import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useWindowSize from "./hooks/useWindowSize";
import "./App.css";
import pannellum from "pannellum";
import "pannellum/build/pannellum.css";

import Photo1 from "./images/1.jpg";
import Photo2 from "./images/2.jpg";
import Photo3 from "./images/3.jpg";
import Photo4 from "./images/4.jpg";
import Photo5 from "./images/5.jpg";
import Photo6 from "./images/6.jpg";
import Photo7 from "./images/7.jpg";
import Photo8 from "./images/8.jpg";
import Photo9 from "./images/9.jpg";
import Photo10 from "./images/10.jpg";
import Photo11 from "./images/11.jpg";
import information from "./images/information.png";
import navigate from "./images/up-arrow.png";
import plus from "./images/plus.png";
import minus from "./images/minus.png";
import fullscreen from "./images/fullscreen.png";
import resize from "./images/resize.png";
import arrow from "./images/arrow.png";

const Pano = styled.div`
  width: 100%;
  height: 80vh;
`;

const StyledApp = styled.div`
  > p {
    text-align: center;
  }

  .pnlm-panorama-info {
    border-radius: 10px 10px 0 0;
    bottom: 0;
    left: 50%;
    max-width: 200px;
    padding: 4px 8px;
    text-align: center;
    transform: translate3d(-50%, 0, 99999px);
    width: 100%;

    .pnlm-title-box {
      display: inline-block;
    }
  }

  .pnlm-hotspot {
    background: #fff;

    &.pnlm-info {
      box-shadow: inset 0 0 0 2px #fff;
      background: #000 url(${information}) center no-repeat;
      background-size: 40%;
    }

    &.pnlm-scene {
      background: #fff url(${navigate}) center no-repeat;
      background-size: 40%;
    }
  }

  .pnlm-zoom-in {
    background: #fff url(${plus}) center no-repeat;
    background-size: 40%;
  }

  .pnlm-zoom-out {
    background: #fff url(${minus}) center no-repeat;
    background-size: 40%;
  }

  .pnlm-fullscreen-toggle-button-inactive {
    background: #fff url(${fullscreen}) center no-repeat;
    background-size: 60%;
  }

  .pnlm-fullscreen-toggle-button-active {
    background: #fff url(${resize}) center no-repeat;
    background-size: 60%;
  }

  .pnlm-orientation-button-inactive {
    background: #fff url(${arrow}) center no-repeat;
    background-size: 60%;
  }
`;

const App = () => {
  const { width } = useWindowSize();
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (width && !isMounted) {
      setIsMounted(!isMounted);
      pannellum.viewer("panorama", {
        default: {
          firstScene: "entrance",
          sceneFadeDuration: 1000,
          autoLoad: true,
          // hotSpotDebug: true,
          hfov: width <= 600 ? 65 : 130,
          type: "equirectangular",
          autoRotate: -1,
        },

        scenes: {
          entrance: {
            panorama: Photo1,
            pitch: -2.5,
            yaw: -177.3,
            title: "Entrada",
            hotSpots: [
              {
                pitch: -2.6,
                yaw: 173.6,
                type: "scene",
                text: "Hall",
                sceneId: "hall",
              },
              {
                pitch: -2.6,
                yaw: 85.7,
                type: "scene",
                text: "Jardim",
                sceneId: "entranceGarden",
              },
              {
                pitch: -2.6,
                yaw: -159,
                type: "scene",
                text: "Corredor",
                sceneId: "entranceHall",
              },
              {
                pitch: 1.5,
                yaw: 163.0,
                type: "info",
                text: "Recepção",
              },
            ],
          },

          entranceGarden: {
            panorama: Photo4,
            pitch: 9.23821156408863,
            yaw: -36.80456329198735,
            title: "Jardim",
            hotSpots: [
              {
                pitch: 2.3,
                yaw: -102.6,
                type: "scene",
                text: "Entrada",
                sceneId: "entrance",
              },
            ],
          },

          entranceHall: {
            panorama: Photo7,
            pitch: 2.99,
            yaw: 71,
            title: "Corredor",
            hotSpots: [
              {
                pitch: 0.6,
                yaw: 20,
                type: "scene",
                text: "Entrada",
                sceneId: "entrance",
              },
              {
                pitch: 2.6,
                yaw: 94,
                type: "scene",
                text: "Hall",
                sceneId: "hall",
              },
              {
                pitch: -0.1,
                yaw: 169.9,
                type: "scene",
                text: "Piscina",
                sceneId: "pool",
              },
              {
                pitch: 3.2,
                yaw: 82,
                type: "info",
                text: "Recepção",
              },
            ],
          },

          hall: {
            panorama: Photo2,
            pitch: 3.9357109706252715,
            yaw: -52.186128033928625,
            title: "Hall",
            hotSpots: [
              {
                pitch: -4.9,
                yaw: 148.7,
                type: "scene",
                text: "Entrada",
                sceneId: "entrance",
                targetYaw: 2.557833650145909,
                targetPitch: -14.156381144191233,
              },
              {
                pitch: 3.9,
                yaw: -80.0,
                type: "scene",
                text: "Coordenação",
                sceneId: "admin",
                targetYaw: 2.557833650145909,
                targetPitch: -14.156381144191233,
              },
              {
                pitch: 0.1,
                yaw: -9.58,
                type: "scene",
                text: "Sala de palestras",
                sceneId: "meetingRoom",
              },
              {
                pitch: 0.4,
                yaw: -116.8,
                type: "scene",
                text: "Conheça nossos quartos",
                sceneId: "rooms",
              },
              {
                pitch: 10.1,
                yaw: 84.0,
                type: "info",
                text: "Enfermagem",
              },
            ],
          },

          admin: {
            panorama: Photo8,
            pitch: -5.130381733434135,
            yaw: 0.7344421067609127,
            title: "Coordenação",
            hotSpots: [
              {
                pitch: 2.2,
                yaw: 96.6,
                type: "scene",
                text: "Hall",
                sceneId: "hall",
                targetYaw: 2.557833650145909,
                targetPitch: -14.156381144191233,
              },
              {
                pitch: 2.1,
                yaw: 64.5,
                type: "info",
                text: "Nossa cozinha",
              },
              {
                pitch: -11.7,
                yaw: 26.1,
                type: "info",
                text: "Renilde <br/> <small>Diretora</small>",
              },
              {
                pitch: -18.7,
                yaw: -64.3,
                type: "info",
                text: "Jacqueline <br/> <small>Coordenadora</small>",
              },
            ],
          },

          rooms: {
            panorama: Photo9,
            pitch: -5.130381733434135,
            yaw: 0.7344421067609127,
            title: "Nossos Quartos",
            hotSpots: [
              {
                pitch: 1,
                yaw: 41,
                type: "scene",
                text: "Sacada / Academia",
                sceneId: "gym",
              },
              {
                pitch: -2.5,
                yaw: -60,
                type: "scene",
                text: "Hall",
                sceneId: "hall",
                targetYaw: 2.557833650145909,
                targetPitch: -14.156381144191233,
              },
              {
                pitch: -25.5,
                yaw: 174.3,
                type: "info",
                text: "Temos uma ótima biblioteca com conteúdos importantes",
              },
            ],
          },

          meetingRoom: {
            panorama: Photo3,
            pitch: 3.25,
            yaw: 17.3,
            title: "Sala de palestras",
            hotSpots: [
              {
                pitch: 2.2,
                yaw: 135.4,
                type: "scene",
                text: "Piscina",
                sceneId: "pool",
                targetYaw: 2.5,
                targetPitch: -14.1,
              },
              {
                pitch: 2.2,
                yaw: 74.4,
                type: "scene",
                text: "Piscina",
                sceneId: "pool",
                targetYaw: 2.5,
                targetPitch: -14.1,
              },
              {
                pitch: -1.6,
                yaw: -81.6,
                type: "scene",
                text: "Hall",
                sceneId: "hall",
                targetPitch: 10.5,
                targetYaw: -132.9,
              },
              {
                pitch: 14.6,
                yaw: 121.8,
                type: "info",
                text: "Onde fazemos nossas vídeo conferências em grupo",
              },
            ],
          },

          pool: {
            panorama: Photo11,
            pitch: 7.0,
            yaw: 23.5,
            title: "Piscina",
            hotSpots: [
              {
                pitch: 1.56,
                yaw: 44.9,
                type: "scene",
                text: "Sala de palestras",
                sceneId: "meetingRoom",
                targetPitch: -13.9,
                targetYaw: -56.7,
              },
              {
                pitch: 0.4,
                yaw: 152.6,
                type: "scene",
                text: "Refeitório",
                sceneId: "diningHall",
              },
              {
                pitch: 1.56,
                yaw: 69.9,
                type: "scene",
                text: "Quarto",
                sceneId: "room",
              },
              {
                pitch: -41.0,
                yaw: 137.3,
                type: "info",
                text: "Nossa hortinha",
              },
            ],
          },

          gym: {
            panorama: Photo10,
            pitch: -0.8,
            yaw: -140,
            title: "Sacada / Academia",
            hotSpots: [
              {
                pitch: 2.9,
                yaw: 152.5,
                type: "scene",
                text: "Quartos",
                sceneId: "rooms",
                targetYaw: -3.8,
                targetPitch: -66,
              },
              {
                pitch: 0,
                yaw: -126.8,
                type: "scene",
                text: "Quartos",
                sceneId: "rooms",
                targetYaw: -3.8,
                targetPitch: -66,
              },
            ],
          },

          diningHall: {
            panorama: Photo5,
            pitch: -3.84,
            yaw: 77,
            title: "Refeitório",
            hotSpots: [
              {
                pitch: 1.52,
                yaw: -36.6,
                type: "scene",
                text: "Piscina",
                sceneId: "pool",
              },
              {
                pitch: 1.52,
                yaw: 36.5,
                type: "scene",
                text: "Quarto",
                sceneId: "room",
              },
            ],
          },
          room: {
            panorama: Photo6,
            pitch: -12,
            yaw: 143.0,
            title: "Quarto",
            hotSpots: [
              {
                pitch: -0.7,
                yaw: -34.0,
                type: "scene",
                text: "Piscina",
                sceneId: "pool",
              },

              {
                pitch: -0.7,
                yaw: -49,
                type: "info",
                text: "Um de nossos banheiros",
              },
            ],
          },
        },
      });
    }
  }, [width, isMounted]);

  return (
    <StyledApp>
      <p>Tour virtual por Vila Serena SP</p>
      {width && <Pano id="panorama"></Pano>}
    </StyledApp>
  );
};

export default App;
